import { Combobox } from '@kobalte/core/combobox';
import { createEffect, createResource, createSignal, Match, Show, Switch } from 'solid-js';
import { ActivityIndicator, DialogContent, Dialog, Input, Picture, DialogTrigger } from '@troon/ui';
import { useNavigate } from '@solidjs/router';
import { twMerge } from '@troon/tailwind-preset/merge';
import { IconMapPin } from '@troon/icons/map-pin';
import { IconArrowRightMd } from '@troon/icons/arrow-right-md';
import { IconChevronRight } from '@troon/icons/chevron-right';
import { FacilityType, gql } from '../graphql';
import { cachedQuery } from '../graphql/cached-get';
import type { SearchFacilityFragment, SearchPlaceFragment, SearchRegionFragment } from '../graphql';
import type { ComponentProps } from 'solid-js';

type Rec = Record<string, unknown>;

type IGroup<G extends Rec, I extends Rec> = G & {
	items: Array<IItem<I & { itemType: 'place' | 'region' | 'empty' }>>;
	value?: never;
};

type IItem<I extends Rec> = I & {
	value: string;
	displayValue?: string;
	itemType: 'facility' | 'place' | 'region' | 'empty';
	disabled?: boolean;
	items?: never;
};
type IFacility = IItem<SearchFacilityFragment> & {
	itemType: 'facility';
	href: string;
};
type IFacilityGroup = IGroup<{ title: string }, SearchFacilityFragment>;
type IPlace = IItem<SearchPlaceFragment> & { itemType: 'place' };
type IRegion = IItem<SearchRegionFragment> & { itemType: 'region' };
type ILocationGroup = IGroup<{ title: string }, SearchPlaceFragment | SearchRegionFragment>;

type IEmpty = IItem<{
	itemType: 'empty';
	href: string;
	showAllLink: boolean;
}>;
type IEmptyGroup = IGroup<{ title: string }, IEmpty>;

const getEmptyState = (showAllLink: boolean): Results => [
	{ title: '', items: [{ itemType: 'empty', displayValue: 'All…', showAllLink, value: '', href: '/courses' }] },
];

type Props = {
	defaultValue?: string;
	onSelectPlace: (loc: IPlace) => void;
	onSelectRegion: (loc: IRegion) => void;
	inputClass?: string;
	mobileInputClass?: string;
	placeholder?: string;
	showAllLink?: boolean;
};

const defaultFilter = () => true;

export function SearchLocations(props: Props) {
	return (
		<>
			<div class="hidden grow md:block">
				<SearchLocationsLargeScreen {...props} />
			</div>
			<div class="shrink grow truncate md:hidden">
				<SearchLocationsMobile {...props} />
			</div>
		</>
	);
}

function SearchLocationsLargeScreen(props: Props) {
	const [input, setInput] = createSignal(props.defaultValue ?? '');
	const [locations] = createResource(() => ({ query: input() }), search);
	const navigate = useNavigate();

	createEffect(() => {
		setInput(props.defaultValue ?? '');
	});

	return (
		<Combobox<IFacility | IPlace | IRegion | IEmpty, IFacilityGroup | ILocationGroup | IEmptyGroup>
			defaultFilter={defaultFilter}
			onInputChange={setInput}
			onChange={(item) => {
				if (item?.itemType === 'facility' || item?.itemType === 'empty') {
					navigate(item.href);
					return;
				}
				if (item?.itemType === 'place') {
					props.onSelectPlace(item as IPlace);
				}
				if (item?.itemType === 'region') {
					props.onSelectRegion(item as IRegion);
				}
			}}
			options={locations.latest ?? getEmptyState(!!props.showAllLink)}
			triggerMode="focus"
			modal={false}
			optionTextValue="value"
			optionLabel="displayValue"
			optionValue="value"
			optionDisabled="disabled"
			optionGroupChildren="items"
			placeholder={props.placeholder ?? 'Where do you want to play?'}
			sameWidth
			fitViewport
			closeOnSelection
			selectionBehavior="replace"
			sectionComponent={({ section }) =>
				section.rawValue.title ? <Combobox.Section class="px-3">{section.rawValue.title}</Combobox.Section> : null
			}
			itemComponent={Item}
		>
			<Combobox.Label class="sr-only">Search by location</Combobox.Label>
			<Combobox.Control>
				<Combobox.Input
					as={Input}
					// @ts-expect-error
					type="search"
					class={
						props.inputClass ??
						'rounded-none border-0 border-none ps-9 focus-visible:ring-2 focus-visible:ring-brand-100'
					}
					prefixElement={<IconMapPin class="text-lg text-brand" />}
					value={input()}
				/>
			</Combobox.Control>
			<Combobox.Portal>
				<Combobox.Content
					// eslint-disable-next-line tailwindcss/no-arbitrary-value
					class="z-50 flex w-[var(--kb-popper-anchor-width)] flex-col overflow-y-auto overflow-x-hidden overscroll-contain rounded border border-neutral-300 bg-white py-4 shadow-md"
				>
					{/* TODO: adding enter/exit animations causes this content to never disappear
					   https://github.com/kobaltedev/kobalte/issues/454
				     animate-out fade-out zoom-out slide-out-to-top-16 ui-expanded:animate-in ui-expanded:fade-in ui-expanded:zoom-in ui-expanded:slide-in-from-top-16
					*/}
					<Show when={locations.loading}>
						<ActivityIndicator class="mb-3" />
					</Show>
					<Combobox.Listbox />
				</Combobox.Content>
			</Combobox.Portal>
		</Combobox>
	);
}

function SearchLocationsMobile(props: Props) {
	const [input, setInput] = createSignal(props.defaultValue ?? '');
	const [locations] = createResource(() => ({ query: input() }), search);
	const navigate = useNavigate();
	const [open, setOpen] = createSignal(false);
	const [contentRef, setContentRef] = createSignal<HTMLElement>();

	createEffect(() => {
		setInput(props.defaultValue ?? '');
	});

	createEffect(() => {
		if (open() && contentRef()) {
			const autofocus = contentRef()?.querySelector('[autofocus]');
			const [firstFocusable] = contentRef()?.querySelectorAll('input:not([type=hidden]),textarea,button,a') ?? [];
			((autofocus ?? firstFocusable) as HTMLInputElement | undefined)?.focus();
		}
	});

	return (
		<Dialog key="search" open={open()} onOpenChange={setOpen}>
			<DialogTrigger
				appearance="transparent-current"
				class={twMerge(
					'flex w-full min-w-32 grow cursor-pointer flex-nowrap items-center justify-start gap-x-2 truncate whitespace-nowrap rounded py-3 ps-1 font-normal normal-case outline-none transition-all duration-200 hover:bg-brand-100 active:bg-brand-100 disabled:bg-transparent disabled:opacity-50 aria-disabled:opacity-50 ui-highlighted:bg-neutral-100',
					props.mobileInputClass,
				)}
			>
				<IconMapPin class="text-lg text-brand" />
				{input() || (
					<span class="block shrink grow-0 truncate text-neutral-700">
						{props.placeholder ?? 'Where do you want to play?'}
					</span>
				)}
			</DialogTrigger>
			<DialogContent header={<span class="sr-only">Search by location</span>} headerLevel="h3" height="full">
				<div ref={setContentRef}>
					<Combobox<IFacility | IPlace | IRegion | IEmpty, IFacilityGroup | ILocationGroup | IEmptyGroup>
						defaultFilter={defaultFilter}
						onInputChange={setInput}
						onChange={(item) => {
							if (item?.itemType === 'facility' || item?.itemType === 'empty') {
								navigate(item.href);
								return;
							}
							if (item?.itemType === 'place') {
								props.onSelectPlace(item as IPlace);
							}
							if (item?.itemType === 'region') {
								props.onSelectRegion(item as IRegion);
							}
							setOpen(false);
						}}
						options={locations.latest ?? getEmptyState(!!props.showAllLink)}
						triggerMode="focus"
						modal={false}
						optionTextValue="value"
						optionLabel="displayValue"
						optionValue="value"
						optionDisabled="disabled"
						optionGroupChildren="items"
						placeholder={props.placeholder ?? 'Where do you want to play?'}
						open
						defaultOpen
						selectionBehavior="replace"
						class="flex flex-col gap-4"
						sectionComponent={({ section }) =>
							section.rawValue.title ? <Combobox.Section class="px-3">{section.rawValue.title}</Combobox.Section> : null
						}
						itemComponent={Item}
					>
						<Combobox.Label class="sr-only">Search by location</Combobox.Label>
						<Combobox.Control>
							<Combobox.Input
								as={Input}
								autofocus
								// @ts-expect-error
								type="search"
								class="ps-8"
								prefixElement={<IconMapPin class="text-brand" />}
								value={input()}
							/>
						</Combobox.Control>
						<Show when={locations.loading}>
							<ActivityIndicator />
						</Show>
						<Combobox.Listbox />
					</Combobox>
				</div>
			</DialogContent>
		</Dialog>
	);
}

const Empty: ComponentProps<typeof Combobox<IEmpty>>['itemComponent'] = (props) => {
	return (
		<div class="flex flex-col gap-3 px-3">
			<p class="min-w-min text-wrap px-3 text-center text-sm">
				Search by entering a <b>city</b>, <b>state</b>, or <b>course name</b>
				<Show when={props.item.rawValue.showAllLink}> to view tee time availability, rates, and course info</Show>.
			</p>
			<Show when={props.item.rawValue.showAllLink}>
				<div class="border-t border-neutral pt-3 ">
					<Combobox.Item
						item={props.item}
						class="flex w-full min-w-32 grow cursor-pointer flex-nowrap items-center justify-start gap-x-2 whitespace-nowrap rounded p-3 text-center font-medium text-brand-600 outline-none transition-all duration-200 hover:bg-brand-100 active:bg-brand-100 disabled:bg-transparent disabled:opacity-50 aria-disabled:opacity-50 ui-highlighted:bg-neutral-100"
					>
						<Combobox.ItemLabel class="flex w-full items-center justify-center">
							View All Troon Affiliated Courses <IconArrowRightMd />
						</Combobox.ItemLabel>
					</Combobox.Item>
				</div>
			</Show>
		</div>
	);
};

const Item: ComponentProps<typeof Combobox<IFacility | IPlace | IRegion | IEmpty>>['itemComponent'] = (props) => {
	return (
		<Switch>
			<Match when={props.item.rawValue.itemType === 'empty'}>
				{/* @ts-expect-error */}
				<Empty {...props} />
			</Match>
			<Match when>
				<Combobox.Item
					item={props.item}
					class="flex w-full min-w-32 grow cursor-pointer flex-nowrap items-center justify-start gap-x-2 overflow-hidden whitespace-nowrap rounded p-3 font-medium outline-none transition-all duration-200 hover:bg-brand-100 active:bg-brand-100 disabled:bg-transparent disabled:opacity-50 aria-disabled:opacity-50 ui-highlighted:bg-neutral-100"
				>
					<Switch>
						<Match when={props.item.rawValue.itemType === 'facility'}>
							<FacilityItem {...(props.item.rawValue as IFacility)} />
						</Match>
						<Match when={props.item.rawValue.itemType === 'place'}>
							<PlaceItem {...(props.item.rawValue as IPlace)} />
						</Match>
						<Match when={props.item.rawValue.itemType === 'region'}>
							<RegionItem {...(props.item.rawValue as IRegion)} />
						</Match>
					</Switch>
				</Combobox.Item>
			</Match>
		</Switch>
	);
};

function FacilityItem(props: IFacility) {
	return (
		<>
			<div class="aspect-square size-16 shrink-0 overflow-hidden rounded bg-neutral-500">
				<Show when={props.metadata?.hero?.url}>
					<Picture src={props.metadata!.hero!.url} sizes="4rem" alt="" width={128} height={128} class="aspect-square" />
				</Show>
			</div>
			<div class="shrink grow items-start justify-center truncate">
				<Combobox.ItemLabel class="truncate font-semibold">{props.name}</Combobox.ItemLabel>
				<Show when={props.metadata?.address?.city || props.metadata?.address?.state}>
					<p class="text-sm text-neutral-700">
						<Show when={props.metadata?.address?.city}>{props.metadata?.address?.city}, </Show>
						{props.metadata?.address?.state}
					</p>
				</Show>
			</div>
			<IconChevronRight class="size-4 shrink-0 self-center text-brand-600" />
		</>
	);
}

function PlaceItem(props: IPlace) {
	return (
		<>
			<div class="flex aspect-square size-16 items-center justify-center overflow-hidden rounded bg-brand-100 text-brand">
				<IconMapPin />
			</div>
			<div class="flex grow flex-col items-start justify-center">
				<Combobox.ItemLabel class="font-semibold">{props.displayValue}</Combobox.ItemLabel>
			</div>
		</>
	);
}

function RegionItem(props: IRegion) {
	return (
		<>
			<div class="flex aspect-square size-16 items-center justify-center overflow-hidden rounded bg-brand-100 text-brand">
				<IconMapPin />
			</div>
			<div class="flex grow flex-col items-start justify-center">
				<Combobox.ItemLabel class="font-semibold">{props.displayValue}</Combobox.ItemLabel>
			</div>
		</>
	);
}

type Results = Array<ILocationGroup | IFacilityGroup> | [IEmptyGroup];

async function search(opts: { query: string }) {
	if (opts.query.length < 3) {
		return null;
	}

	const res = await searchFacilities({ query: opts.query });
	if (!res?.search) {
		return null;
	}

	const items: Results = [];

	if (res.search.facilities.length) {
		items.push({
			title: 'Courses',
			// @ts-expect-error TODO
			items: (res.search.facilities as Array<SearchFacilityFragment>).slice(0, 3).map(
				(facility) =>
					({
						...facility,
						value: facility.slug,
						displayValue: facility.name,
						href:
							facility.isBookable &&
							[FacilityType.DailyFeeResort, FacilityType.Residential, FacilityType.SemiPrivate].includes(facility.type)
								? `/course/${facility.slug}/reserve-tee-time`
								: `/course/${facility.slug}`,
						itemType: 'facility',
					}) satisfies IFacility,
			),
		} satisfies IFacilityGroup);
	}

	const locations = { title: 'Locations', items: [] as Array<IPlace | IRegion> };

	if (res.search.regions.length) {
		locations.items.push(...(res.search.regions as Array<SearchRegionFragment>).map(mapRegionToLocation));
	}

	if ([res.search.places].length) {
		locations.items.push(...(res.search.places as Array<SearchPlaceFragment>).map(mapPlaceToLocation));
	}

	if (locations.items.length) {
		items.push(locations);
	}

	return items;
}

function mapPlaceToLocation(loc: SearchPlaceFragment) {
	return {
		value: `ll-${loc.coordinates.latitude}-${loc.coordinates.longitude}`.replace(/[^\w]+/g, '-'),
		displayValue: [loc.name, loc.regionName].join(', '),
		itemType: 'place',
		...loc,
	} satisfies IPlace;
}

function mapRegionToLocation(loc: SearchRegionFragment) {
	return {
		value: loc.id,
		displayValue: [loc.name, loc.countryName].join(', '),
		itemType: 'region',
		...loc,
	} satisfies IRegion;
}

const searchQuery = gql(`query searchFacilities($query: String!) {
	search: search(query: $query) {
		facilities { ...SearchFacility }
		places { ...SearchPlace }
		regions { ...SearchRegion }
	}
}`);
gql(`fragment SearchFacility on Facility {
	slug
	name
	type
	isBookable
	metadata {
		address { city, state }
		hero { url }
	}
}`);
gql(`fragment SearchPlace on Place {
	name
	coordinates {
		latitude
		longitude
	}
	regionName
	countryName
}`);
gql(`fragment SearchRegion on Region {
	id
	name
	countryName
}`);

const searchFacilities = cachedQuery(searchQuery);
